import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../../components/seo';
import Hero from '../../components/hero';
import Footer from '../Layout/Footer';
import ContactArea from '../contact-area';
import { device } from '../../theme';
import SimpleHeader from '../Layout/SimpleHeader';
import Header from '../Layout/Header';
import LeadsCaptureFormArea from '../leads-capture-form-area';
import LeadsCaptureForm from '../leads-capture-form';

const LeadsCapture = ({ location, data }) => {
  const {
    showMenu,
    slug,
    title,
    subtitle,
    description,
    image,
    background,
    mobileBackground,
    publicTender,
    postTitle,
    postDescription,
    postButtonDescription,
    postButtonAction,
    unitId,
  } = data;

  const backgroundImage = [
    mobileBackground.fluid,
    {
      ...background.fluid,
      media: device.smallOnly,
    },
  ];

  return (
    <>
      <SEO title={title} description={description} location={location} image={image.fluid.src} />
      {showMenu ? <Header /> : <SimpleHeader />}
      <Hero
        title={title}
        subtitle={subtitle}
        description={description}
        image={image}
        backgroundImage={backgroundImage}
        dark
      />
      <LeadsCaptureFormArea
        form={
          <LeadsCaptureForm
            description={`${title} | ${slug}`}
            publicTenders={publicTender}
            postTitle={postTitle}
            postDescription={postDescription}
            postButtonDescription={postButtonDescription}
            postButtonAction={postButtonAction}
            unitId={unitId}
          />
        }
      />
      <Footer onlyBottom={!showMenu} />
    </>
  );
};

LeadsCapture.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    showMenu: PropTypes.bool.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    background: PropTypes.object.isRequired,
    mobileBackground: PropTypes.object.isRequired,
    publicTender: PropTypes.array.isRequired,
    postTitle: PropTypes.string.isRequired,
    postDescription: PropTypes.string.isRequired,
    postButtonDescription: PropTypes.string.isRequired,
    postButtonAction: PropTypes.string.isRequired,
    unitId: PropTypes.number.isRequired,
  }).isRequired,
};
export default LeadsCapture;
