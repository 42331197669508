import { useCallback, useEffect, useState } from 'react';
import contactService from '../services/contactService';
import schoolUnitsService from '../services/schoolUnitsService';
import classesService from '../services/classesService';

const UNIT_IGNORE_ID = 2;

export default ({ skipSegments = false } = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const showLoading = () => {
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };

  const fetchSegments = useCallback(async () => {
    const { data } = await classesService.segments();

    return data;
  }, []);

  const fetchSchoolUnits = useCallback(async () => {
    const { data } = await schoolUnitsService.units();

    return data.filter(({ id }) => id !== UNIT_IGNORE_ID);
  }, []);

  const fetchKnowledgeSources = useCallback(async () => {
    const { data } = await contactService.knowledgeSources();

    return data;
  }, []);

  const loadFormData = useCallback(async () => {
    const promises = [fetchSchoolUnits(), fetchKnowledgeSources()];

    if (!skipSegments) promises.push(fetchSegments());

    try {
      showLoading();
      const [units, knowledgeSources, segments = []] = await Promise.all(promises);

      setFormData({ units, knowledgeSources, segments });
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  }, [fetchSchoolUnits, fetchKnowledgeSources, fetchSegments]);

  useEffect(() => {
    loadFormData();
  }, [loadFormData]);

  return { ...formData, isLoading, showLoading, hideLoading };
};
