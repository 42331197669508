import styled from 'styled-components';

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalHeader = styled.h5`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacings.lg};
`;

export const ModalBody = styled.div`
  text-align: justify;
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const SectionBottom = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;
