import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from '../../components/ui/Wrapper';
import { Wrapper } from './leads-capture-form-area.style';

const LeadsCaptureFormArea = ({ form }) => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col lg={8} className="offset-lg-2">
            {form}
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

LeadsCaptureFormArea.propTypes = {
  form: PropTypes.node.isRequired,
};

export default LeadsCaptureFormArea;
