import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import LeadsCapture from '../../containers/LeadsCapture';

const LeadsCaptureTemplate = ({ data, location, pageContext }) => {
  const leadsCaptureData = data.contentfulLeadsCapture;
  return <LeadsCapture location={location} data={leadsCaptureData} />;
};

export const courseQuery = graphql`
  query($slug: String!) {
    contentfulLeadsCapture(slug: { eq: $slug }) {
      id
      showMenu
      title
      slug
      subtitle
      description
      postTitle
      postDescription
      postButtonDescription
      postButtonAction
      unitId
      image {
        fluid(maxWidth: 601, quality: 100, toFormat: WEBP) {
          ...GatsbyContentfulFluid
          sizes
        }
      }
      background {
        fluid(quality: 100, toFormat: WEBP) {
          ...GatsbyContentfulFluid
        }
      }
      mobileBackground {
        fluid(quality: 100, toFormat: WEBP) {
          ...GatsbyContentfulFluid
        }
      }
      publicTender {
        slug
        externalId
      }
    }
  }
`;

LeadsCaptureTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    contentfulLeadsCapture: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default LeadsCaptureTemplate;
